import '@/utils/hideErrors';
import 'normalize.css';
import '@/v1/assets/scss/styles.scss';

import { createApp } from 'vue';
// HOTFIX: transaction funnel dependency. should be refactored
import vSelect from 'vue-select';
import { initLedger } from '@/services/ledger';
import App from '@/v1/App';
import { setupI18n } from '@/v1/i18n';
import router from '@/v1/router';
import store from '@/v1/store';

import StateRouteWatcher from '@/v1/plugins/StateRouteWatcher';
import externalAssetsHostReplaceMixin from './mixins/ExternalAssetsHostReplaceMixin';
import getParams, { removeSensitiveDataFromUrl } from '@/v1/utils/getParams';
import getGlobalThemeId from '@/v1/utils/getGlobalThemeId';
import VueErrorsHandler from '@/v1/utils/vueErrorsHandler';
import { init as initGlobalErrorListener } from '@/v1/utils/globalCriticalErrorsHandler';
import { inputScrollHotfix } from '@/helpers/AndroidHelper';

import 'vue-toast-notification/dist/theme-sugar.css';
import 'intro.js/introjs.css';
import '@/v1/assets/scss/lib/introjs.scss';
import '@/v1/assets/scss/lib/darkIntrojs.scss';
import IntroJs from 'intro.js';
import introHints from '@/directives/launchIntro';
import { initNSure } from '@/utils/loadNSure';
import { initAPI } from '@/v1/packages/common/services/api';

import { initOneTimeTokenLogin } from '@/v1/utils/oneTimeTokenLogin';

initLedger();
initAPI(store);

const params = getParams();

const {
  requestId,
  oneTimeToken,
  locale,
  businessAccountProductUuid,
  transactionFlow,
  partnerUserId,
  successReturnURL,
  failureReturnURL,
} = params;

removeSensitiveDataFromUrl();
initGlobalErrorListener();
inputScrollHotfix();

const i18n = setupI18n({ locale });
const app = createApp(App);

initOneTimeTokenLogin(params);

store.dispatch('init', {
  requestId,
  oneTimeToken,
  themeId: getGlobalThemeId(),
  businessAccountProductUuid,
  transactionFlow,
  locale,
  partnerUserId,
  successReturnURL,
  failureReturnURL,
});

app
  .component('VSelect', vSelect)
  .use(i18n)
  .use(store)
  .use(router)
  .use(IntroJs)
  .directive('intro', introHints)
  .mixin(externalAssetsHostReplaceMixin) // HACK: Fixes the host for images assets.
  /* HACK: Listens store changes from basic=verification through common-lib,
   * and push to router to transaction-funnel. */
  .use(StateRouteWatcher, {
    store,
    router,
  })
  .mount('#app');

if (process.env.NODE_ENV === 'production') {
  app.config.errorHandler = VueErrorsHandler;
}

// HACK: PD-7427 fix infinite loop
app.config.warnHandler = () => null;

initNSure();
